<template>
  <!-- TODO: Vuetify -->
  <v-card width="100%">
    <v-card-title>
      Übersicht: Shop Artikel
      <v-spacer></v-spacer>
      <v-btn outlined @click="refreshData">
        <icon-refresh />
      </v-btn>
      <v-btn class="ma-2" outlined color="green" @click="createProduct">
        Artikel anlegen
      </v-btn>
    </v-card-title>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      fixed-header
      :headers="headers"
      :items="products"
      :search="search"
      :items-per-page="15"
      :footer-props="{
        'items-per-page-text': 'Artikel pro Seite',
        'items-per-page-options': [15, 25, 50, -1]
      }"
      @click:row="showAlert"
    >
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import IconRefresh from "@/components/icons/IconRefresh";

export default {
  name: "Products",
  components: { IconRefresh },
  data() {
    return {
      products: [],
      search: "",
      headers: [
        { text: "Artikelnummer", value: "productNumber" },
        {
          text: "Artikel",
          sortable: false,
          value: "name"
        },
        { text: "Preis (brutto)", value: "calculatedPrice.totalPrice" },
        { text: "Auf Lager", value: "stock" },
        { text: "Lieferanten-Nr.", value: "manufacturerNumber" },
        { text: "Aktiv", value: "active" }
      ]
    };
  },
  methods: {
    refreshData() {
      this.loadProducts();
      this.$emit("rehydrate data");
    },
    createProduct() {
      alert("HELLO PRODUCT");
    },
    showAlert(a) {
      alert("Alert! \n" + a.name);
    },
    loadProducts() {
      axios
        .get(`${process.env.VUE_APP_SHOP_URL}/sales-channel-api/v3/product`, {
          headers: {
            "sw-access-key": process.env.VUE_APP_SW_ACCESS_KEY
          }
        })
        .then(response => {
          this.products = response.data.data;
        });
    }
  },
  mounted() {
    this.loadProducts();
  }
};
</script>

<style lang="scss">
tbody {
  tr {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
